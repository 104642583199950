// @flow
import React from 'react';

export default function Header() {
  return (
    <h1>
      <span>unicode</span>
      <span style={{ color: '#05f' }}>.</span>
      <i>tixie.name</i>
    </h1>
  );
}
